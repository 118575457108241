.formInputs{
    width:80%;
}
.errorColor{
    color:var(--magentaColor);
}
.formBox{
    padding: 1rem .5rem;
    /* border: 1px solid var(--mainLight); */
    border-radius: 15px;
    -webkit-border-radius:15px;
    -moz-border-radius:15px;
    background-color: var(--lightBrown);
}

@media (min-width: 576px) {
    .formBox{
        padding: 1.5rem 1rem;           
    }
}
@media (min-width: 768px) {
    .formBox{
        padding: 2rem 1.5rem;           
    }
}
@media (min-width: 992px) {
    .formBox{
        padding: 2.5rem 2rem;           
    }
}
@media (min-width: 1200px) {
    .formBox{
        padding: 3rem 3rem;           
    }
}